<template>
  <a-drawer
    width="1200"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>关联设备</b>
    </a-divider>
    <!-- 数据展示 -->
    <a-table
      :components="drag(columns)"
      :loading="loading"
      :size="tableSize"
      rowKey="id"
      :columns="columns"
      :data-source="list"
      :pagination="false"
      :scroll="{ x: 1200 }"
      :bordered="tableBordered">
      <span slot="address" slot-scope="text, record">
        <span>{{ record.pcaName }} {{ record.addressName }}</span>
      </span>
      <span slot="devStatus" slot-scope="text, record">
        <a-tag color="green" v-if="record.devStatus == 0">正常</a-tag>
        <a-tag color="red" v-else-if="record.devStatus == 1">报警</a-tag>
      </span>
      <span slot="onLine" slot-scope="text, record">
        <a-tag color="orange" v-if="record.onLine == 2">异常</a-tag>
        <a-tag color="green" v-if="record.onLine == 1">在线</a-tag>
        <a-tag color="red" v-else-if="record.onLine == 0">离线</a-tag>
      </span>
      <span slot="sfArrearage" slot-scope="text, record">
        <a-tag color="blue" v-if="record.sfArrearage == 0">否</a-tag>
        <a-tag color="orange" v-else-if="record.sfArrearage == 1">是</a-tag>
      </span>
      <span slot="status" slot-scope="text, record">
        <a-tag color="orange" v-if="record.status == 0">关</a-tag>
        <a-tag color="blue" v-else-if="record.status == 1">开</a-tag>
      </span>
      <span slot="payType" slot-scope="text, record">
        <a-tag color="green" v-if="record.payType == 0">微信</a-tag>
        <a-tag color="blue" v-else-if="record.payType == 1">平台</a-tag>
      </span>
      <span slot="userInfo" slot-scope="text, record">
        <div class="spanBox">
          <span>{{ record.userName }} {{ record.userPhone }}</span>
        </div>
      </span>
      <span slot="payPattern" slot-scope="text, record">
        <a-tag color="blue" v-if="record.payPattern == 0">平台预付费</a-tag>
        <a-tag color="blue" v-else-if="record.payPattern == 1">表端预付费</a-tag>
      </span>
    </a-table>
    <!-- 分页 -->
    <a-pagination
      class="ant-table-pagination"
      show-size-changer
      show-quick-jumper
      :current="queryParam.pageNum"
      :total="total"
      :page-size="queryParam.pageSize"
      :showTotal="total => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange"
      @change="changeSize"
    />
  </a-drawer>
</template>

<script>

import { userNeeaDevicePage } from '@/api/pages/user'
import { tableMixin } from '@/store/table-mixin'
import tableDragResize from '@/utils/tableDragResize'
export default {
  name: 'Device',
  props: {
  },
  mixins: [tableMixin, tableDragResize],
  data () {
    return {
      submitLoading: false,
      open: false,
      loading: false,
      total: 0,
      list: [],
      // 查询参数
      queryParam: {
        userId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '设备名称',
          width: 160,
          dataIndex: 'devName',
          fixed: 'left',
          align: 'center',
          ellipsis: true
        },
        {
          title: '水表编号',
          width: 160,
          dataIndex: 'devSn',
          fixed: 'left',
          align: 'center',
          ellipsis: true
        },
        {
          title: '设备类型',
          width: 120,
          dataIndex: 'typeName',
          align: 'center',
          ellipsis: true
        },
        {
          title: '设备编号',
          width: 160,
          dataIndex: 'devCode',
          align: 'center',
          ellipsis: true
        },
        {
          title: '余额',
          width: 100,
          dataIndex: 'balance',
          align: 'center',
          ellipsis: true
        },
        {
          title: '地址',
          width: 200,
          dataIndex: 'address',
          scopedSlots: { customRender: 'address' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '设备状态',
          width: 100,
          dataIndex: 'devStatus',
          scopedSlots: { customRender: 'devStatus' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '绑定人信息',
          width: 200,
          dataIndex: 'userInfo',
          scopedSlots: { customRender: 'userInfo' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '用量告警',
          width: 100,
          dataIndex: 'dosageWarn',
          align: 'center',
          ellipsis: true
        },
        {
          title: '付费模式',
          width: 100,
          dataIndex: 'payPattern',
          scopedSlots: { customRender: 'payPattern' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '是否有欠费',
          width: 100,
          dataIndex: 'sfArrearage',
          scopedSlots: { customRender: 'sfArrearage' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '设备开关阀',
          width: 100,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center',
          ellipsis: true
        }
      ]
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    info (id) {
      this.queryParam.userId = id
      this.getList()
    },
    getList() {
      this.loading = true
      userNeeaDevicePage(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
        this.open = true
      })
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    }
  }
}
</script>
<style lang="less" scoped>
  .ant-upload-preview {
    position: relative;
    width: 100px;
    height: 100px;
    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;
      &:hover {
        opacity: 1;
      }
      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }
    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
    }
  }
</style>
