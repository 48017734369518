import request from '@/utils/request'

// 用户信息 分页
export function userPage(query) {
  return request({
    url: '/user/user/page',
    method: 'get',
    params: query
  })
}

// 用户信息 详情
export function userInfo(query) {
  return request({
    url: '/user/user/detail',
    method: 'get',
    params: query
  })
}

// 用户信息 重置密码
export function userReset(data) {
  return request({
    url: '/user/user/reset',
    method: 'post',
    data
  })
}

// 用户信息 启用、禁用
export function userStatus(data) {
  return request({
    url: '/user/user/status',
    method: 'post',
    data
  })
}

// 关联用户设备列表
export function deviceUserPage(query) {
  return request({
    url: '/wisdom/wisdom-device/user/page',
    method: 'get',
    params: query
  })
}
// 查询用户的设备分页
export function userNeeaDevicePage(query) {
  return request({
    url: '/wisdom/wisdom-user-neea/dev/page',
    method: 'get',
    params: query
  })
}

// 用户列表查询设备操作日志分页
export function deviceUserLog(query) {
  return request({
    url: '/wisdom/wisdom-device-log/user/page',
    method: 'get',
    params: query
  })
}
